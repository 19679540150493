<template lang="pug">
  div
    Card(title="Copilot", :noPadding="true")
      template(v-slot:buttons)
        Button(prepend-icon="pencil", @click="editCopilot()")

      dl.dl-horizontal
        dt Display on Inspires
        dd
          Icon.text-success(v-if="partner.inspires_display_copilot", icon="check")
          Icon.text-danger(v-else, icon="times")
        dt Inspires Customisations
        dd
          Icon.text-success(v-if="partner.inspires_customisations_enabled", icon="check")
          Icon.text-danger(v-else, icon="times")
        dt Main Domain
        dd(:class="{'text-supplement': !partner.site || partner.site && !partner.site.domain}") {{ partner.site && partner.site.domain || "-" }}
        dt Allowed Domains
        dd
          ul.list-comma-separated(v-if="partner.site && partner.site.copilot_domains")
            li(v-for="domain in partner.site && partner.site.copilot_domains") {{ domain }}
          span.text-supplement(v-else) -
        dt Featured Image
        dd
          Icon.text-success(v-if="partner.site && partner.site.featured_image", icon="check")
          span.text-supplement(v-else) -
        dt CSS
        dd
          Icon.text-success(v-if="partner.site && partner.site.copilot_css", icon="check")
          span.text-supplement(v-else) -
        dt Static JS
        dd
          Icon.text-success(v-if="partner.site && partner.site.copilot_static_js", icon="check")
          span.text-supplement(v-else) -
        dt Dynamic JS
        dd
          Icon.text-success(v-if="partner.site && partner.site.copilot_dynamic_js", icon="check")
          span.text-supplement(v-else) -
        dt Cache Duration
        dd {{ cacheDurationLabel[partner.copilot_cache_duration] }}
        dt Advisor Branding
        dd {{ partner.copilot_advisor_branding || "Automatic" }}
        dt CTA Label
        dd(:class="{'text-supplement': partner.site && !partner.site.copilot_cta_label}") {{ partner.site && partner.site.copilot_cta_label || "-" }}
</template>

<script>
import EditPartnerCopilotModal from "@/modals/Partners/EditPartnerCopilotModal"

export default {
  metaInfo() {
    return { title: `${this.partner.name} Site` }
  },
  props: {
    partner: {}
  },
  data: () => ({
    cacheDurationLabel: {
      0: "Disabled",
      1: "1 minute",
      2: "2 minutes",
      3: "3 minutes",
      4: "4 minutes",
      5: "5 minutes",
      6: "6 minutes",
      7: "7 minutes",
      8: "8 minutes",
      9: "9 minutes",
      10: "10 minutes",
      15: "15 minutes",
      1440: "1 day",
      4320: "3 days",
      20160: "2 weeks",
      43200: "30 days",
      129600: "90 days",
      525600: "1 year"
    }
  }),
  methods: {
    async editCopilot() {
      if (
        await this.$modal.show(EditPartnerCopilotModal, {
          endpoint: `partners/${this.partner.id}/copilot`
        })
      ) {
        this.$emit("reload")
      }
    }
  }
}
</script>
