<template lang="pug">
  FormModal(title="Edit Coplilot", :endpoint="endpoint", method="PUT", :data="data", v-bind="$attrs", @success="success")
    InputGroup(label="Display on inspires", name="inspires_display_copilot")
      ToggleSwitch(v-model="data.inspires_display_copilot", label="Is this control redundant?")

    InputGroup(label="Inspires Customisations", name="inspires_customisations_enabled")
      ToggleSwitch(v-model="data.inspires_customisations_enabled", label="Allow block customisations on Inspires")

    InputGroup(label="Main Domain", name="domain")
      TextInput(v-model="data.domain")

    InputGroup(label="Allowed Domains", name="copilot_domains", description="Press 'enter' after each domain. Main domain is included automatically.")
      TagsInput(v-model="data.copilot_domains", prepend-icon="browser", placeholder="Enter domain...")

    InputGroup(label="Image", name="featured_image")
      FileInput(v-model="data.featured_image")

    InputGroup(label="CSS", name="copilot_css")
      TextInput(v-model="data.copilot_css", textarea)

    InputGroup(label="Static JS", name="copilot_static_js")
      TextInput(v-model="data.copilot_static_js", textarea)

    InputGroup(label="Dynamic JS", name="copilot_dynamic_js")
      TextInput(v-model="data.copilot_dynamic_js", textarea)

    InputGroup(label="Advisor Branding", name="copilot_advisor_branding")
      Select(v-model="data.copilot_advisor_branding", endpoint="options/copilot-advisor-branding", prepend-option="Automatic")

    InputGroup(label="Copilot CTA Label", name="copilot_cta_label")
      TextInput(v-model="data.copilot_cta_label")

    InputGroup(label="Cache Duration", name="copilot_cache_duration")
      Select(v-model="data.copilot_cache_duration", :options="cacheDurationOptions")
</template>

<script>
import { get, pick } from "lodash-es"

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true,
    data: {},
    cacheDurationOptions: [
      { id: 0, name: "Disabled" },
      { id: 1, name: "1 minute" },
      { id: 2, name: "2 minutes" },
      { id: 3, name: "3 minutes" },
      { id: 4, name: "4 minutes" },
      { id: 5, name: "5 minutes" },
      { id: 6, name: "6 minutes" },
      { id: 7, name: "7 minutes" },
      { id: 8, name: "8 minutes" },
      { id: 9, name: "9 minutes" },
      { id: 10, name: "10 minutes" },
      { id: 15, name: "15 minutes" },
      { id: 1440, name: "1 day" },
      { id: 4320, name: "3 days" },
      { id: 20160, name: "2 weeks" },
      { id: 43200, name: "30 days" },
      { id: 129600, name: "90 days" },
      { id: 525600, name: "1 year" }
    ]
  }),
  async mounted() {
    let response = await this.$api.get(this.endpoint)

    this.data = pick(get(response, "data", {}), [
      "copilot_advisor_branding",
      "copilot_cache_duration",
      "copilot_css",
      "copilot_cta_label",
      "copilot_domains",
      "copilot_dynamic_js",
      "copilot_static_js",
      "domain",
      "featured_image",
      "inspires_customisations_enabled",
      "inspires_display_copilot"
    ])

    this.loading = false
  },
  methods: {
    success(result) {
      this.$notification.success(`Copilot successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
