<template lang="pug">
  DataTableRow(:row="row")
    DataTableColumn(title="Type", sortable="block_type", width="min")
      BlockTypeIcon(:type="row.block_type")
    DataTableColumn(title="Content", sortable="title")
      div.flex.space-x-6
        MediaIcon.flex-shrink-0.mt-1.w-12(:media="row.featured_image", :blank="true", @click="editFeaturedImage(row.id)")
        div
          router-link.font-medium(:to="{name: editRoute(row), params: {id: row.id}}") {{ row.title }}
          div.flex.mt-2.space-x-6.text-sm
            div.flex.items-center.space-x-3(v-if="row.type")
              span: Icon.text-primary(:icon="row.type.icon")
              span {{ row.type.name }}
            div.flex.items-center.space-x-3(v-if="row.depth")
              span: Icon.text-primary(:icon="row.depth.icon")
              span {{ row.depth.name }}
    DataTableColumn(title="Libraries", icon="books", sortable="libraries_count")
      ul.list-comma-separated(v-if="row.libraries.length"): li(v-for="library in row.libraries") {{ library }}
      span.text-supplement(v-else) -
    DataTableColumn(title="Publisher(s)", icon="publisher", sortable="publishers_count")
      ul.list-comma-separated(v-if="row.publishers.length"): li(v-for="publisher in row.publishers") {{ publisher }}
      span.text-supplement(v-else) -
    DataTableColumn(icon="destination", sortable="destinations_count", sort-order="desc", width="max") {{ row.destinations_count | number }}
    DataTableColumn(icon="subjectArea", sortable="tags_count", sort-order="desc", width="max") {{ row.tags_count | number }}
    DataTableColumn(icon="collection", sortable="collections_count", sort-order="desc", width="max") {{ row.collections_count | number }}
    DataTableColumn(title="Status", width="max")
      ContentStatusSwitcher(:id="row.id", :status="row.status")
    template(v-slot:actions)
      Dropdown.flip-menu(icon="ellipsis-v")
        DropdownLink(icon="pencil", :to="{name: editRoute(row), params: {id: row.id}}") Edit
        DropdownLink(icon="clone", @click="duplcate(row.id)") Duplicate
</template>

<script>
import { ContentStatusSwitcher } from "@approach-guides/shared-assets"
import { MediaIcon } from "@syntax51/app-kit"
import BlockTypeIcon from "../../components/BlockTypeIcon.vue"
import EditContentFeaturedImageModal from "@/modals/Content/EditContentFeaturedImageModal"

export default {
  components: {
    BlockTypeIcon,
    ContentStatusSwitcher,
    MediaIcon
  },
  props: {
    row: {
      type: Object,
      required: true
    }
  },
  methods: {
    async editFeaturedImage(id) {
      if (await this.$modal.show(EditContentFeaturedImageModal, { endpoint: `content/${id}/featured-image` })) {
        this.$refs.dataTable.reload()
      }
    },
    editRoute(row) {
      return row.block_type === "image" ? "image.edit" : "content.edit"
    },
    async duplcate(id) {
      let response = await this.$api.get(`content/${id}/duplicate`)
      this.$router.push({ name: "content.edit", params: { id: response.data.id } })
      this.$notification.success("Duplicated!")
    }
  }
}
</script>
