<template lang="pug">
  DataTable(v-if="endpoint", ref="dataTable", title="Users", :endpoint="endpoint", searchable)
    template(v-slot:buttons)
      Button(type="primary", @click="newUser()") NEW USER
    //- template(v-slot:filters)
      DataTableFilter(v-model="filters.accountType", endpoint="options/account-types", label="Account Type", prepend-option="All Account Types")
    template(v-slot:rows="rows")
      DataTableRow(v-for="(row, i) in rows", :key="i", :row="row")
        DataTableColumn(title="User", sortable="name")
          router-link(:to="{name: 'users.view', params: {id: row.id}}") {{ row.full_name }}
        DataTableColumn(title="Contact")
          div {{ row.email }}
          div {{ row.phone | default("-") }}
        DataTableColumn(title="UID") {{ row.alt_id }} #[CopyToClipboardIcon(:content="row.alt_id")]
        DataTableColumn(icon="collection", sortable="collections_count", sort-order="desc", width="max") {{ row.collections_count | number }}
        DataTableColumn(title="Template", width="max") {{ row.template && row.template.name }}
        DataTableColumn(title="Last Active", sortable="active_at", sort-order="desc", width="max") {{ row.active_at | datetime }}
        DataTableColumn(title="Added", sortable="created_at", sort-order="desc", width="max") {{ row.created_at | datetime }}
        template(v-slot:actions)
          Dropdown.flip-menu(icon="ellipsis-v")
            //DropdownLink(icon="magic", @click="impersonateUser(row)") Impersonate
            DropdownLink(icon="pencil", @click="editUser(row)") Edit
            DropdownLink(icon="lock", @click="changeUserPassword(row)") Change Password
            DropdownLink(icon="trash", @click="deleteUser(row)") Delete
</template>

<script>
import NewPartnerUserModal from "@/modals/Partners/NewPartnerUserModal"
import EditUserModal from "@/modals/Users/EditUserModal"
import ChangeUserPasswordModal from "@/modals/Users/ChangeUserPasswordModal"

export default {
  props: {
    partner: {}
  },
  metaInfo() {
    return { title: `${this.partner.name} Users` }
  },
  computed: {
    endpoint() {
      return this.partner && this.partner.id && `users?partner_id=${this.partner.id}`
    }
  },
  methods: {
    async newUser() {
      if (await this.$modal.show(NewPartnerUserModal, { partner: this.partner })) {
        this.$refs.dataTable.reload()
      }
    },
    async impersonateUser(user) {
      let response = await this.$api.get(`users/${user.id}/impersonate`)
      let token = response && response.data && response.data.token
      window.open(`${process.env.VUE_APP_PARTNER_URL}impersonate/${token}`, "_blank")
    },
    async editUser(user) {
      if (await this.$modal.show(EditUserModal, { endpoint: `users/${user.id}` })) {
        this.$refs.dataTable.reload()
      }
    },
    async changeUserPassword(user) {
      if (await this.$modal.show(ChangeUserPasswordModal, { user_id: user.id })) {
        this.$refs.dataTable.reload()
      }
    },
    async deleteUser(user) {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${user.full_name}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`users/${user.id}`)) {
          this.$notification.success(`User **${user.full_name}** successfully deleted.`)
          this.$refs.dataTable.reload()
        }
      }
    }
  }
}
</script>
