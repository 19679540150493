<template lang="pug">
  AppLayout(:title="title")

    Row(lg="row")

      Column(lg="2/3")

        DataTable(title="Content added to a user collection", endpoint="dashboard/content-added-to-collections")
          template(v-slot:rows="rows")
            DataTableRow(v-for="row in rows", :key="row.id", :row="row")
              DataTableColumn(title="Collection"): router-link(:to="{name: 'collections.view', params: {id: row.collection.id}}") {{ row.collection.name }}
              DataTableColumn(title="Content"): router-link(:to="{name: 'content.edit', params: {id: row.content.id}}") {{ row.content.title }}
              DataTableColumn(title="Added", width="max") {{ row.added_at | datetime }}

        DataTable(title="Content added by users", endpoint="dashboard/content-added-by-users")
          template(v-slot:rows="rows")
            DataTableRow(v-for="row in rows", :key="row.id", :row="row")
              DataTableColumn(title="Title", sortable="title"): router-link(:to="{name: 'content.edit', params: {id: row.id}}") {{ row.title }}
              DataTableColumn(title="User", sortable="title"): router-link(:to="{name: 'users.view', params: {id: row.user.id}}") {{ row.user.name }}
              DataTableColumn(title="Partner", sortable="title"): router-link(:to="{name: 'partners.view', params: {id: row.partner.id}}") {{ row.partner.name }}
              DataTableColumn(title="Added", width="max") {{ row.created_at | datetime }}

        DataTable(title="Searches with no results", subtitle="within the last month", endpoint="dashboard/searches-with-no-results")
          template(v-slot:rows="rows")
            DataTableRow(v-for="row in rows", :key="row.id", :row="row")
              DataTableColumn(title="Search Term", sortable="term") {{ row.term }}
              DataTableColumn(title="Filters", width="max"): Icon(icon="filter", v-tooltip="row.filters")
              DataTableColumn(title="When", width="max") {{ row.created_at | datetime('medium') }}

        DataTable(title="Content with no destination", endpoint="dashboard/content-with-no-destination")
          template(v-slot:rows="rows")
            DataTableRow(v-for="row in rows", :key="row.id", :row="row")
              DataTableColumn(title="Title", sortable="title"): router-link(:to="{name: 'content.edit', params: {id: row.id}}") {{ row.title }}
              DataTableColumn(title="Added", width="max") {{ row.created_at | datetime }}
              DataTableColumn(title="Updated", width="max") {{ row.updated_at | datetime }}

      Column(lg="1/3")

        Card.card-active-users(title="Active Users", subtitle="Within the last 7 days")
          UserList(endpoint="dashboard/active-users", no-results="No active users within the last 7 days.")

        Card.card-new-users(title="New Users", subtitle="Within the last 2 days")
          UserList(endpoint="dashboard/newest-users", no-results="No new users within the last 2 days.")
</template>

<script>
import UserList from "@/components/UserList"

export default {
  metaInfo() {
    return { title: this.title, bodyAttrs: { class: "page-dashboard" } }
  },
  components: { UserList },
  data: () => ({
    title: "Dashboard"
  })
}
</script>

<style>
.page-dashboard {
  .card {
    &-active-users,
    &-new-users {
      .card-body-inner {
        @apply p-8;
      }
    }
  }
}
</style>
