<template lang="pug">
  FormModal.modal-new-collection(title="New Collection", endpoint="collections", :data="data", v-bind="$attrs", @success="success")

    InputGroup(label="Privacy", name="type")
      Radios(v-model="data.type", inline)
        Radio(value="partner", @change="onTypeChange()")
          Icon(icon="users")
          div
            div: strong Shared
            div.text-supplement Shared among all members of a team.
        Radio(value="personal", @change="onTypeChange()")
          Icon(icon="user")
          div
            div: strong Personal
            div.text-supplement Private and visible only to the user.

    InputGroup(label="Name", name="name")
      TextInput(v-model="data.name", prepend-icon="collection", textarea, :rows="1")

    InputGroup(label="Partner", name="partner_id")
      Select(v-model="data.partner_id", endpoint="options/partners", prepend-icon="users")

    InputGroup(v-if="data.type === 'personal' && data.partner_id", label="User", name="user_id")
      Select(v-model="data.user_id", :endpoint="`options/partners/${data.partner_id}/users`", prepend-icon="user")

    InputGroup(v-if="data.type === 'partner' && data.partner_id", label="Folder", name="folder_id")
      Select(v-model="data.folder_id", prepend-option="No Folder", prepend-icon="folder", prepend-icon-prefix="fas", :endpoint="`partners/${data.partner_id}/folders`", option-label="label")

    InputGroup(v-else-if="data.type === 'personal' && data.user_id", label="Folder", name="folder_id")
      Select(v-model="data.folder_id", prepend-option="No Folder", prepend-icon="folder", prepend-icon-prefix="fas", :endpoint="`users/${data.user_id}/folders`", option-label="label")

    InputGroup(label="Internal code — optional", name="code", description="Press 'enter' after each code")
      TagsInput(v-model="data.code", placeholder="Enter code...", prepend-icon="tag")

    InputGroup(label="Publish on Inspires", name="inspires_publish_at")
      DatePicker(v-model="data.inspires_publish_at", label="Display on brand's Inspires content experiences page", show-time)

    InputGroup(label="Feature on Inspires", name="inspires_feature_at")
      DatePicker(v-model="data.inspires_feature_at", label="Display on Inspires content experiences page", show-time)

    InputGroup(name="expires_at", label="Expiration Date - optional")
      DatePicker(v-model="data.expires_at", placeholder="This collection will not expire. To set an expiration, select a date", :presets="expiresAtPresets", show-time)
</template>

<script>
import { add as dateAdd } from "date-fns"

export default {
  data: () => ({
    data: {
      type: "partner",
      partner_id: null,
      user_id: null,
      name: "",
      folder_id: null,
      code: [],
      inspires_publish_at: null,
      inspires_feature_at: null,
      expires_at: null
    },
    expiresAtPresets: [
      { label: "Today", date: new Date() },
      { label: "1 month", date: dateAdd(new Date(), { months: 1 }) },
      { label: "6 months", date: dateAdd(new Date(), { months: 6 }) },
      { label: "1 year", date: dateAdd(new Date(), { years: 1 }) },
      { label: "3 years", date: dateAdd(new Date(), { years: 3 }) },
      { label: "5 years", date: dateAdd(new Date(), { years: 5 }) }
    ]
  }),
  methods: {
    onTypeChange() {
      this.data.user_id = null
      this.data.folder_id = null
    },
    success(result) {
      this.$notification.success(`Collection **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>

<style>
.modal-new-collection,
.modal-edit-collection,
.modal-duplicate-collection {
  .form-check-group {
    @apply space-x-3;
  }

  .form-check {
    @apply w-1/2;

    &-custom-input {
      @apply hidden;
    }

    &.is-checked .form-label {
      @apply border-primary-default;
    }
  }

  .form-label {
    @apply block rounded border-2 border-solid py-6 pl-6 pr-8;
  }

  .form-check-content {
    @apply ml-0 flex;

    .icon {
      @apply mr-3 mt-2 w-12 text-2xl text-supplement;
    }
  }
}
</style>
