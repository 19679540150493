<template lang="pug">
  AppLayout(:title="title")
    template(v-slot:buttons, v-if="mode === 'edit'")
      Button(prepend-icon="clone", @click="duplicate()")
      Button(type="danger", prepend-icon="trash", @click="deleteContent()")

    Row.add-edit-content(
      lg="row",
      class="lg:flex-row-reverse",
      v-if="!loading"
    )

      div(
        v-show="addContentErrors || url.parsed"
        class="fixed bottom-16 right-16 z-50"
      )
        Alert.bg-white.mb-6(type="danger", title="Oops. Seems like something went wrong.", v-show="addContentErrors") Please check the fields highlighted in red.
        Button.add-edit-content__save-button.text-center.w-full(type="primary", @click="onSaveButtonClick", v-show="url.parsed") {{ mode === 'edit' ? 'UPDATE' : 'SAVE' }}

      Column(lg="1/3")
        div(class="add-edit-content__preview sticky")

          Card(v-if="mode === 'edit'")
            dl.dl-horizontal.dl-plain.dl-no-padding
              dt.flex.items-center.pt-0 Status
              dd.pt-0: ContentStatusSwitcher(:id="data.id", :status="data.status", @change="onContentStatusChange")
              dt Author
              dd
                router-link(:to="{name: 'users.view', params: { id: data.created_by.id }}", v-if="data.created_by") {{ data.created_by.full_name }}
                span.text-supplement(v-else) -
              dt.pb-0 Published by
              dd.pb-0
                router-link(:to="{name: 'users.view', params: { id: data.admin.id }}", v-if="data.admin") {{ data.admin.full_name }}
                span.text-supplement(v-else) -

          Card
            div.content.has-type.type-video.has-languages.language-universal.has-destinations.destination-polynesia-hawaii-big-island
              h3.text-lg
                a.content__link(:href="data.content_url", target="_blank", v-html="$markdown(data.title)")
              div.mt-3.space-x-2
                span.font-bold {{ data.lead_in }}
                span(v-html="$markdown(data.synopsis)")
                em(v-show="(data.type_id === 1 || data.type_id === 2) && data.author") By {{ data.author }}
                em(v-show="(data.type_id === 4 || data.type_id === 8) && data.runtime") Runtime: {{ data.runtime }} min.
              //div.content__video-wrapper.vimeo
                iframe(
                  src="https://player.vimeo.com/video/127335923?title=0&amp;byline=0&amp;portrait=0&amp;player_id=xT7uxwPknjZy2prV",
                  id="xT7uxwPknjZy2prV",
                  frameborder="0",
                  webkitallowfullscreen="",
                  mozallowfullscreen="",
                  allowfullscreen="",
                  data-embed-type="vimeo"
                )

          Card(v-if="mode === 'edit' && data.featured_image", title="Featured Image", class="add-edit-content__featured-image")
            //- img(:src="data.featured_image")
            div(v-if="data.featured_image.generated_conversions && data.featured_image.generated_conversions.thumb")
              Table
                TableRow(v-for="(conversion, name) in data.featured_image.generated_conversions", :key="name")
                  TableColumn(title="Conversion")
                    div.font-medium {{ name }}
                    div(class="text-sm text-supplement")
                      div(v-if="conversion.meta") {{ conversion.meta.width }}x{{ conversion.meta.height }}
                      div(v-else) -
                  TableColumn(icon="crop-alt", width="min")
                    Icon(:icon="conversion.has_conversion ? 'check' : 'times'", :class="conversion.has_conversion ? 'text-green-500' : 'text-gray-300'")
                  TableColumn(icon="phone-laptop", width="min")
                    Icon(:icon="conversion.has_responsive ? 'check' : 'times'", :class="conversion.has_responsive ? 'text-green-500' : 'text-gray-300'")

              div.mt-10.text-sm.space-y-2.text-supplement
                div.flex.items-center.space-x-3
                  span.flex.justify-center.w-6: Icon(icon="crop-alt")
                  span Conversion generated
                div.flex.items-center.space-x-3
                  span.flex.justify-center.w-6: Icon(icon="phone-laptop")
                  span Responsive images generated

            div(v-else)
              p There are no conversions generated for this image.


      Column(lg="2/3")
        Card.add-edit-content__form(:title="title")

          div(class="step step--first border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10")
            h1.mt-1.text-2xl Enter URL
            div.step__content(class="mt-8")
              Form.form--1(endpoint="content/parse-url", :data="data", @error="onParseUrlError", @success="onParseUrlSuccess")
                InputGroup(name="content_url")
                  TextInput(prepend-icon="browser", v-model="data.content_url", auto-focus, :disabled="url.parsing", @input="onUrlChange", ref="url")
                  Alert.alert--custom(type="danger", content="This URL is already in the database.", v-show="url_exists")
                  Alert.alert--custom(:type="url.status", :content="url.message", v-show="url.status && url.message")
                template(v-slot:footer)
                  div.mt-6.text-right.w-full(v-show="!url.parsing && !url.parsed")
                    Button(type="primary", append-icon="long-arrow-right", @click="onParseUrl()", submit) Next

          Form.form--2(:method="method", :endpoint="endpoint", :data="data", @error="onAddContentError", @success="onAddContentSuccess", ref="form")
            div(class="step border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10")
              h1.mt-1.text-2xl The basics
              div.step__content(v-if="url.parsed", class="mt-8")

                InputGroup(name="title", label="Title")
                  TextInput(v-model="data.title", textarea, :rows="1")

                MarkdownInput(v-model="data.synopsis", label="Synopsis", name="synopsis", :suggested-max-length="350")

                InputGroup(name="type_id", label="Media Type")
                  Radios(v-model="data.type_id", inline)
                    div(class="gap-2 grid grid-cols-2 w-full md:grid-cols-4")
                      Radio.media-types-item(v-for="mediaType in options.mediaTypes", :key="mediaType.id", :value="mediaType.id", :class="{'is-selected': data.type_id === mediaType.id}")
                        span.media-types-item__icon(v-if="mediaType.icon"): Icon(:icon="mediaType.icon")
                        span {{ mediaType.name }}

            div(class="step border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10")
              h1.mt-1.text-2xl Just a few more things
              div.step__content(v-if="url.parsed", class="mt-8")

                InputGroup(name="tag_ids", label="Subject Area(s)")
                  MultiSelect(v-model="data.tag_ids", placeholder="Enter subject area(s)...", endpoint="options/tags", :spellcheck="false")

                InputGroup(name="destination_ids", label="Destination(s)")
                  MultiSelect(v-model="data.destination_ids", placeholder="Enter destination(s)...", endpoint="search/destinations", option-label="label", :spellcheck="false", searchable)

                InputGroup(name="depth", label="Depth")
                  Radios(v-model="data.depth_id", inline)
                    div(class="gap-2 grid w-full sm:grid-cols-3")
                      Radio.content-depths-item(v-for="(depth, i) in options.depths", :key="depth.id", :value="depth.id", :class="{'is-selected': data.depth_id === depth.id}")
                        span.content-depths-item__icon: Icon(:icon="depth.icon")
                        span {{ depth.name }}

                div(v-show="data.type_id === 1 || data.type_id === 2 || data.type_id === 4 || data.type_id === 8", class="space-y-8 sm:flex sm:space-x-8 sm:space-y-0")
                  div(v-if="data.type_id === 1 || data.type_id === 2", class="sm:w-1/2")
                    InputGroup(name="author", label="Author")
                      TextInput(v-model="data.author")
                  div(v-if="data.type_id === 4 || data.type_id === 8", class="sm:w-1/2")
                    InputGroup(name="runtime", label="Runtime")
                      NumberInput(v-model="data.runtime", placeholder="Round to the nearest minute...")

            div(class="step border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10")
              h1.mt-1.text-2xl Finally, anything else you'd like to do?
              div.step__content(v-if="url.parsed", class="mt-8")

                InputGroup(name="collection_ids", label="Collection(s) - optional")
                  MultiSelect(v-model="data.collection_ids", placeholder="Add this to a collection...", endpoint="search/collections", searchable)

                div.flex.mb-8
                  div.flex-grow: InputGroup(label="Image - optional", name="featured_image", description="Max upload size: 10MB")
                    ImageInput(v-model="data.featured_image", :disabled="fetchingImage")
                  div.ml-4
                    label.form-inputgroup-label &nbsp;
                    Button(class="border border-gray-400 flex h-[47.5px] items-center justify-center w-[47.5px] rounded hover:bg-white hover:text-primary hover:border-primary disabled:hover:border-gray-400 disabled:hover:text-paragraph", @click="refetchImage", :disabled="fetchingImage")
                      Icon(:icon="fetchingImage ? 'spinner-third': 'redo'", :prefix="fetchingImage ? 'fad' : 'far'", :spin="fetchingImage")

                InputGroup(name="expires_at", label="Expiration Date - optional")
                  DatePicker(v-model="data.expires_at", placeholder="This content will not expire. To set an expiration, select a date", :presets="expiresAtPresets")

            div(class="step border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10")
              div.relative: Badge(type="success", size="small", class="absolute bottom-full") ADMIN ONLY
              h1.mt-1.text-2xl A few extra pieces of information
              div.step__content(v-if="url.parsed", class="mt-8")
                InputGroup(name="publisher_ids", label="Publishers")
                  MultiSelect(v-model="data.publisher_ids", placeholder="Associate this with a publisher...", endpoint="options/publishers")
                InputGroup(name="language_ids", label="Language(s)")
                  MultiSelect(v-model="data.language_ids", endpoint="options/languages")
                InputGroup(name="subtitle_ids", label="Subtitle Language(s) - optional", v-if="data.type_id === 4 || data.type_id === 8")
                  MultiSelect(v-model="data.subtitle_ids", endpoint="options/languages")
                InputGroup(name="published_at", label="Publish Date")
                  DatePicker(v-model="data.published_at", :presets="publishedAtPresets")

            div(class="step border-l ml-12 pl-12 pr-12 py-6 relative md:ml-16 md:pl-16 md:pr-20 md:py-10", :class="{'step--last': !url.parsed}")
              div.relative: Badge(type="success", size="small", class="absolute bottom-full") ADMIN ONLY
              h1.mt-1.text-2xl Special tweaks
              div.step__content(v-if="url.parsed", class="mt-8")

                InputGroup(name="lead_in", label="Lead In")
                  TextInput(v-model="data.lead_in", :readonly="!data.lead_in_override")
                    template(v-slot:prepend)
                      Checkbox(v-model="data.lead_in_override")

                InputGroup(name="embed_code_override", label="Override Embed Code")
                  Checkbox(v-model="data.embed_code_override", class="mt-4") Override the embed code.

                InputGroup(name="embed_code", v-show="data.embed_code_override")
                  TextInput(v-model="data.embed_code", textarea, :spellcheck="false")

                InputGroup(name="embed_code", label="Embed Classes")
                  Checkbox(v-model="add_embed_classes", class="mt-4", @change="onEmbedClassChange") Add a responsive embed class.
                  Radios(v-model="data.embed_classes", inline, v-show="add_embed_classes", class="mt-6")
                    Radio(value="responsive-16x9") 16:9
                    Radio(value="responsive-4x3") 4:3

                InputGroup(name="library_ids", label="Libraries")
                  MultiSelect(v-model="data.library_ids", placeholder="Add this to a library...", endpoint="options/libraries")

                InputGroup(name="partner_id", label="Owner")
                  Select(v-model="data.partner_id", endpoint="options/partners", prepend-icon="users")

            template(v-slot:footer)
              div(class="border-l flex flex-grow justify-end ml-12 pb-12 pl-12 pr-12 md:ml-16 md:pb-16 md:pl-16 md:pr-20", v-show="url.parsed")
                Button(type="primary", submit) {{ mode === 'edit' ? 'UPDATE' : 'SAVE' }}
</template>

<script>
import { Alert, Badge } from "@syntax51/app-kit"
import { ContentStatusSwitcher } from "@approach-guides/shared-assets"
import MarkdownInput from "@/components/MarkdownInput.vue"
import { add as dateAdd, sub as dateSub } from "date-fns"
import { debounce, each, get, isEmpty } from "lodash-es"

export default {
  watch: {
    $route: "fetch"
  },
  components: {
    Alert,
    Badge,
    ContentStatusSwitcher,
    MarkdownInput
  },
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    loading: true,
    fetchingImage: false,
    url: {
      status: null,
      message: "",
      parsing: false,
      parsed: false
    },
    addContentErrors: null,
    add_embed_classes: false,
    url_exists: false,
    options: {
      depths: [],
      mediaTypes: []
    },
    publishedAtPresets: [
      { label: "Yesterday", date: dateSub(new Date(), { days: 1 }) },
      { label: "Today", date: new Date() }
    ],
    expiresAtPresets: [
      { label: "Today", date: new Date() },
      { label: "1 month", date: dateAdd(new Date(), { months: 1 }) },
      { label: "6 months", date: dateAdd(new Date(), { months: 6 }) },
      { label: "1 year", date: dateAdd(new Date(), { years: 1 }) },
      { label: "3 years", date: dateAdd(new Date(), { years: 3 }) },
      { label: "5 years", date: dateAdd(new Date(), { years: 5 }) }
    ],
    data: {
      content_url: "",
      title: "",
      type_id: 2,
      featured_image: null,
      synopsis: "",
      tag_ids: [],
      depth_id: 2,
      destination_ids: [],
      author: "",
      runtime: null,
      collection_ids: [],
      expires_at: "",
      library_ids: [1],
      language_ids: [1],
      subtitle_ids: [],
      published_at: null,
      lead_in_override: false,
      embed_code_override: false,
      embed_code: null,
      embed_classes: "",
      partner_id: 1,
      admin: null
    }
  }),
  computed: {
    mode() {
      return this.$route.name === "content.edit" ? "edit" : "add"
    },
    title() {
      return (this.mode === "edit" ? "Edit" : "Add") + " Content"
    },
    method() {
      return this.mode === "edit" ? "PUT" : "POST"
    },
    endpoint() {
      return this.mode === "edit" ? `update-content/${this.$route.params.id}` : "content"
    },
    synopsisFeedback() {
      let status = "info",
        content = "We recommend fewer than 350 characters.",
        length = this.data.synopsis && this.data.synopsis.length

      if (length >= 10 && length <= 350) {
        status = "success"
        content = `This synopsis is ${length} characters.`
      } else if (length > 350) {
        status = "danger"
        content = `This synopsis is **${length}** characters long; we recommend fewer than 350.`
      }

      return { status, content }
    }
  },
  async created() {
    let depthOptions = await this.$api.get("options/content-depths")
    let mediaTypeOptions = await this.$api.get("options/content-types")
    this.options.depths = get(depthOptions, "data.items", [])
    this.options.mediaTypes = get(mediaTypeOptions, "data.items", [])

    if (this.mode === "add") {
      this.data.published_at = new Date()
    } else if (this.mode === "edit") {
      await this.fetch()
    }

    this.loading = false
  },
  methods: {
    async fetch() {
      if (this.mode === "add") return

      this.loading = true
      let response = await this.$api.get(this.endpoint)
      this.data = get(response, "data.item", {})
      this.add_embed_classes = this.data.embed_classes && this.data.embed_classes != ""
      this.url.parsed = true
      this.loading = false
    },
    onParseUrl() {
      this.url.status = "loading"
      this.url.message = "Hold tight! We're fetching data to auto-populate for you."
      this.url.parsing = true
    },
    onParseUrlError(error) {
      if (isEmpty(error)) return
      this.url.status = null
      this.url.message = ""
      this.url.parsing = false

      setTimeout(() => {
        this.$refs.url.focus()
      }, 1)
    },
    onParseUrlSuccess(data) {
      each(data, async (value, key) => {
        if (key === "image") {
          this.data.featured_image = value
        } else {
          this.data[key] = value
        }
      })

      this.url_exists = data.url_exists

      let autoPopulated = []
      let dataKeys = Object.keys(data)
      if (dataKeys.indexOf("title") !== -1) autoPopulated.push("title")
      if (dataKeys.indexOf("synopsis") !== -1) autoPopulated.push("synopsis")
      if (dataKeys.indexOf("type_id") !== -1) autoPopulated.push("media type")
      if (dataKeys.indexOf("image") !== -1) autoPopulated.push("image")

      if (autoPopulated.length) {
        if (autoPopulated.length === 1) {
          this.url.message = `We auto-populated **${autoPopulated[0]}**.`
        } else {
          let lastAutoPoplated = autoPopulated.pop()
          this.url.message = `We auto-populated **` + autoPopulated.join("**, **") + `** and **${lastAutoPoplated}**.`
        }

        this.url.status = "success"
        this.url.message += ` Please review and edit as necessary.`
      } else {
        this.url.status = null
        this.url.message = ""
      }

      this.url.parsing = false
      this.url.parsed = true
    },
    onAddContentSuccess(response) {
      this.$notification.success("Got it!")
      this.addContentErrors = null

      if (this.mode === "add") {
        this.$router.push({ name: "content" })
      } else if (this.mode === "edit") {
        this.data.status = response.item.status
      }
    },
    onAddContentError(response) {
      this.addContentErrors = response.errors
    },
    onEmbedClassChange(value) {
      if (!value) this.data.embed_classes = null
    },
    onUrlChange() {
      if (this.mode === "add") {
        this.resetForm()
      } else if (this.mode === "edit") {
        this.checkUrl()
      }
    },
    checkUrl: debounce(async function () {
      this.url.parsing = true
      this.url.parsed = false
      this.url_exists = false

      if (this.data.content_url) {
        let response = await this.$api.post(`content/parse-url`, {
          content_url: this.data.content_url,
          id: this.data.id
        })

        this.url_exists = get(response, "data.url_exists", false)
        this.data.lead_in = response.data.lead_in

        if (!this.data.featured_image && response.data.image) {
          this.data.featured_image = response.data.image
        }

        this.url.parsed = true
      }

      this.url.parsing = false
    }, 500),
    resetForm() {
      this.$refs.form.clearErrors()
      this.url = { status: null, message: "", parsing: false, parsed: false }
      this.url_exists = false

      this.data.title = ""
      this.data.synopsis = ""
      this.data.type_id = 2
      this.data.tag_ids = []
      this.data.destination_ids = []
      this.data.depth_id = 2
      this.data.author = ""
      this.data.runtime = null
      this.data.collection_ids = []
      this.data.featured_image = null
      this.data.expires_at = ""
      this.data.library_ids = [1]
      this.data.language_ids = [1]
      this.data.subtitle_ids = []
      this.data.published_at = this.mode === "add" ? new Date() : null
      this.data.lead_in_override = false
      this.data.embed_code_override = false
      this.data.embed_code = null
      this.data.embed_classes = ""
      this.data.admin = null
    },
    async duplicate() {
      let response = await this.$api.get(`content/${this.data.id}/duplicate`)
      this.$router.push({ name: "content.edit", params: { id: response.data.id } })
      this.$notification.success("Duplicated!")
    },
    async deleteContent() {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${this.data.title}**?`,
          buttonText: "Delete"
        })
      ) {
        if (await this.$api.delete(`content/${this.data.id}`)) {
          this.$notification.success(`Content **${this.data.title}** successfully deleted.`)
          this.$router.push({ name: "content" })
        }
      }
    },
    onContentStatusChange(response) {
      let { published_at, expires_at, status } = response.data
      this.data.published_at = published_at
      this.data.expires_at = expires_at
      this.data.status = status
    },
    onSaveButtonClick() {
      this.$refs.form.submit()
    },
    async refetchImage() {
      this.fetchingImage = true

      let response = await this.$api.post(`content/parse-url`, {
        content_url: this.data.content_url
      })

      if (response.data.image) {
        this.data.featured_image = response.data.image
      }

      this.fetchingImage = false
    }
  }
}
</script>

<style>
.add-edit-content {
  counter-reset: content-step;

  &__featured-image {
    .table th {
      @apply border-l-0 border-r-0 border-t-0 pt-0;

      &:first-child {
        @apply pl-0;
      }

      &:last-child {
        @apply pr-5;
      }
    }

    .table__column {
      &:first-child {
        @apply pl-0;
      }

      &:last-child {
        @apply pr-5;
      }
    }

    .table__row:last-child td {
      @apply pb-0;
    }
  }

  &__preview {
    top: calc(theme("header.height") + theme("spacing.12"));
  }

  &__save-button .button-inner {
    @apply flex-grow justify-center;
  }

  &__form .card-body-inner {
    @apply p-0;
  }

  .step {
    counter-increment: section;

    &:before {
      @apply absolute left-0 flex h-12 w-12 items-center justify-center rounded-full border bg-white font-semibold text-supplement;
      content: counter(section);
      margin-left: calc(0px - theme("spacing.6"));
      margin-top: -2px;
    }

    &--first {
      @apply pt-12 md:pt-16 lg:pt-20;
    }

    &--last {
      @apply pb-12 md:pb-16 lg:pb-20;
    }

    &__content {
      *:last-child {
        @apply mb-0;
      }
    }
  }

  .alert--custom {
    @apply mt-4 border-none p-0;

    .alert-icon {
      @apply mr-3 h-6 w-8 rounded-full text-xs;
      min-width: auto;
    }
  }

  .content-depths-item,
  .media-types-item {
    @apply ml-0 !important;
    @apply p-0;

    &__icon {
      @apply mr-2 text-lg text-primary-default;
      line-height: 1;
    }

    .form-label {
      @apply flex items-center justify-center rounded border border-gray-400 p-3;
      height: 45px;

      &:hover {
        @apply bg-primary-50;
      }
    }

    .form-check-content {
      @apply ml-0 flex items-center;
    }

    .form-check-custom-input {
      @apply hidden;
    }

    &.is-selected .form-label {
      @apply border-primary-default bg-primary-50 text-primary-default;
    }
  }

  .form--1 {
    .form-inputgroup {
      @apply mb-0;
    }

    .form-feedback {
      @apply hidden;
    }
  }

  .form--2 .form-feedback {
    @apply relative ml-12 mb-0 border-l py-6 pl-12 pr-12 md:ml-16 md:pl-16 md:pr-20 md:pb-10;
  }

  .formatting-help {
    .dropdown-trigger-label {
      @apply text-supplement hover:text-paragraph;
    }

    .dropdown-menu {
      @apply max-w-full py-0;
      min-width: 250px;

      @screen md {
        min-width: 400px;
      }
    }
  }

  .card .form__footer {
    @apply bg-white p-0;
  }
}
</style>
